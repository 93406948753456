<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Sales Price
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row>

                      <v-col cols="12" md="12">
                          <v-autocomplete
                          v-model="sales_type_id"
                          :items="sales_types"
                          chips
                          label="Sales Type"
                          item-text="name"
                          item-value="id"
                          :rules="nameRules"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('sales_type_id')"
                              >
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>

                          <v-autocomplete
                          v-model="product_id"
                          :items="products"
                          chips
                          label="Product"
                          item-text="name"
                          item-value="id"
                          @change="changeProduct()"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('product')"
                              >
                                <v-avatar left>
                                  <v-img :src="img('product',data.item.image_link)"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="img('product',data.item.image_link)">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>

                           <v-text-field
                          v-model="price_format"
                          label="Price"
                          :rules="priceRules"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="begin_price_format"
                          label="Begin Price"
                          :rules="priceRules"
                          required
                        ></v-text-field>

                          <v-textarea
                            v-model="note"
                            label="Note"
                          ></v-textarea>
                         </v-col>



                    </v-row>
               </v-form>
               <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'
export default {
  mixins:[GlobalMixin],
  methods: {
    send_data() {
      const data = new URLSearchParams()
      data.append('note', this.note)
      data.append('begin_price', this.begin_price)
      data.append('sales_type_id', this.sales_type_id)
      data.append('product_id', this.product_id)
      data.append('price', this.price)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = this.send_data()
      await this.axios.post('v1/sales-price/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('sales-price','sales_prices')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = this.send_data()
      await this.axios.put('v1/sales-price/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go('sales-price','sales_prices')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.product_id = ''
      this.begin_price = 0
      this.price = 0
      this.sales_type_id = ''
      this.note = ''
    },
    changeProduct() {
      let produk = this.products.find( x => x.id === this.product_id)
      console.log(produk)
      this.price = parseInt(produk.price)
      this.begin_price = parseInt(produk.begin_price)
    },
    remove(data) {
      this[data] = ''
    },
    img(folder = null ,file = null) {
      if (file !== null) {
         return process.env.VUE_APP_IMGLINK + `${folder}/` + file
      } else {
        return this.imgurl
      }

    },
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    note: '',
    price: 0,
    begin_price: 0,
    product_id: '',
    sales_type_id: '',
    priceRules: [
      v => !!v || 'Field is required',
      v => /^[0-9,]+$/.test(v) || 'Format Price (1,000,xxx)',
    ],
    nameRules: [
      v => !!v || 'Required field'
    ],
    imgurl: 'img/no_img.png',
    foto: null

  }),

  computed: {
    ...mapGetters({
      products: 'data/products',
      sales_types: 'data/sales_types'
    }),
    price_format:{
      get(){
          return this.formatAsCurrency(this.price, 0)
      },
      set(newValue){
          this.price= Number(newValue.replace(/[^0-9\.]/g, ''))
      }
    },
    begin_price_format:{
      get(){
          return this.formatAsCurrency(this.begin_price, 0)
      },
      set(newValue){
          this.begin_price= Number(newValue.replace(/[^0-9\.]/g, ''))
      }
    },


  },
  async created () {
    if (this.edit_data) {
          this.product_id = this.edit_data.product_id
          this.begin_price = parseInt(this.edit_data.begin_price)
          this.price = parseInt(this.edit_data.price)
          this.note = this.edit_data.note
          this.sales_type_id = this.edit_data.sales_type_id
        }
        await this.get_data_master('sales-type','sales_types')
        await this.get_data_master('product','products')
  }
}
</script>
